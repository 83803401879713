var PostToParent = function () {
  this.send = function (options, targetOrigin = '*') {
    parent.postMessage(options, targetOrigin);
  };
};

var post = new PostToParent();

function sendHeight() {
  var body = document.body;
  var html = document.documentElement;
  var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

  if (height < 550) {
    height = 550;
  }

  post.send({ 'key': 'FrameHeight', value: height });
}

window.addEventListener('load', () => {
  sendHeight()
});

document.addEventListener('turbolinks:load', sendHeight);
